* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 0.8rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  letter-spacing: 0.00938em;
}
body {
  background: #ebf1f42b !important;
  /* font-size: calc(4vw + 4vh + 2vmin); */
  font-size: calc(16px + (26 - 16) * ((100vw - 300px) / (1600 - 300)));
}
a {
  text-decoration: none !important;
  color: inherit;
}

.content.active-content {
  display: block;
}
.content {
  display: none;
}
.ProductDetails.active-content {
  background: linear-gradient(310deg, #141727, #1f487c);
  color: #fff;
  /* background: linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%); */
}
.CompletedProjects.active-content {
  background: rgb(248, 54, 0);
  color: #fff;
  /* background: linear-gradient(to right, rgb(248, 54, 0) 0%, rgb(249, 212, 35) 100%); */
}
.InprogressProjects.active-content {
  background: rgb(0, 91, 234);
  color: #fff;
  /* background: linear-gradient(to top, rgb(0, 198, 251) 0%, rgb(0, 91, 234) 100%); */
}
.HoldProjects.active-content {
  background: rgb(255, 177, 153);
  color: #fff;
  /* background: linear-gradient(to top, rgb(255, 8, 68) 0%, rgb(255, 177, 153) 100%); */
}
.footer {
  font-size: small;
}
