h6 {
  font-size: 1rem !important;
}
// .table-dashboard th {
//   word-break: break-word;
//   max-width: 60px;
// }
.table-dashboard {
  .sizeSmall {
    padding: 0 6px;
    // font-size: 0.775rem;
    text-align: left;
    &._font {
      font-size: 0.875rem;
    }
    &._fontColor {
      font-size: 0.875rem;
      color: #193051;
    }

    &._total_completed {
      span {
        // background: #ddd;
        font-size: 0.675rem;
        display: flex;
        border-radius: 3px;
        padding: 0px;
        text-align: left;
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
          0px 1px 1px 0px rgba(0, 0, 0, 0.04),
          0px 1px 3px 0px rgba(0, 0, 0, 0.02);
        span {
          width: auto;
          // background: #539f45;
          display: inline-block;
          padding: 5px 8px;

          color: rgb(0, 0, 0);
          position: relative;
          box-shadow: none;
        }
      }
      &._dash {
        span {
          span {
            padding: 3px 8px;
          }
        }
      }
    }
  }
  .table_row {
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
      box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.04), 0px 1px 3px 0px rgba(0, 0, 0, 0.02);
    }
  }

  .progressbar {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
  }
}
.reportListing {
  display: flex;
  justify-content: center;
  align-items: center;
  li {
    width: auto;
  }
}
.general-toggle {
  button {
    font-size: 0.7rem;
    &.inactive {
      background: #ddd;
      color: #666;
    }
  }
}
.table-borderless {
  td {
    border-bottom: none;
  }
  tbody {
    tr:nth-of-type(odd) {
      background: #f9f9f9;
    }
  }
}
.btnsmallEdit {
  min-width: 40px !important;
  padding: 6px 8px !important;
}
.reportTabs {
  .tabs-reports {
    border-top: 1px solid #ddd;
    .tabs-reports_lft,
    .tabs-reports_rft {
      padding: 10px;
    }
  }
}
.tbcell {
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.04), 0px 1px 3px 0px rgba(0, 0, 0, 0.02);
    cursor: pointer;
  }
}
.formInput {
  padding: 35px;
  background: #eeefff6b;
}
fieldset {
  border-radius: 0 !important;
}
.MuiInput-input {
  label {
    font-size: 12px;
  }
}
.subtitle {
  text-transform: uppercase;
}
.leftlist-activites {
  min-height: 300px;
  border-right: 1px solid #ddd;

  ul {
    li {
      padding: 15px;
    }
    div {
      padding: 15px;
    }
  }
}
.report-table {
  td {
    padding: 3px 10px;
  }
  .sizeSmall._total_completed span {
    box-shadow: none;
    border-radius: 0;
    height: 25px;
    display: flex;
    align-items: center;
  }
  .sizeSmall._total_completed span.not-completed {
    background: #4caf50;
    display: flex;
    align-items: center;
  }
}
.unit_row {
  border-bottom: 2px solid #3c44b1;
  border-top: 2px solid #3c44b1;
  td {
    font-weight: bold;
  }
}
.address-details {
  li {
    p:first-child {
      margin-right: 10px;
      font-weight: bold;
      min-width: 104px;
    }
    p {
      font-size: 14px;
    }
  }
}

.login_after {
  color: #193051;
  font-size: 0.76rem;
}
.account_name {
}
.datepicker_das {
  color: #193051;
  border: none !important;
}

.reportDashboard {
  .threeHeader {
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
  }
}
.resport-listing-task {
  h6 {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-left: -10px;
  }
  li {
    padding: 2px 10px;
    font-size: 14px;
    color: #5e5873;
    display: flex;
    span {
      width: 10px;
      height: 10px;
      border-radius: 60px;
      background: #5e5873;
      display: block;
      margin-right: 10px;
    }
  }
}
.table-scrolling {
  overflow: hidden;
}
.activelink {
  .active {
    div {
      color: #65cfea;
      &:hover {
        color: #65cfea;
      }
    }
  }
}
.activelink .active > div > span {
  background-color: rgba(0, 0, 0, 0.1) !important;
  border-radius: 60px;
}
.table_row:nth-child(even) {
  background: rgba(0, 0, 0, 0.04);
}
.userDate,
.usecontrol {
  color: #ffffff80;
  border: 1px solid #ffffff80 !important;
  input,
  button {
    color: #ffffff80;
  }
}
.usecontrol {
  color: #ffffff80 !important;
  border: 1px solid #ffffff80 !important;
  input,
  button {
    color: #ffffff80 !important;
  }
  svg {
    color: #ffffff80 !important;
  }
}
.project_progress {
  max-height: 200px;
  min-height: 200px;
  overflow: auto;
}
.total_project {
  max-height: 200px;
  overflow: auto;
}
.dash_box_daily_task {
  padding: 0 16px !important;
}
.table_small_padding {
  padding: 8px;
  tr {
    th,
    td {
      &:first-child {
        padding-left: 16px;
      }
    }
  }
  .task_name {
    min-width: 20rem;
  }
}
.table_approve_right {
  tr > th,
  td {
    font-size: 0.95rem;
    padding: 0 8px;
    min-width: 50px;
    max-width: 130px;
    &:last-child {
      text-align: right;
    }
  }
  tr > td {
    font-size: 0.95rem;
  }
}
table {
  tr {
    th,
    td {
      font-size: 0.95rem !important;
    }
  }
}
.report_details_table {
  tr {
    td,
    th {
      font-size: 0.84rem;
      line-height: 1.3;
      text-transform: capitalize;
      min-width: 100px;
    }
  }
}
.add-task-project,
.update-task-project,
.assign-task-project {
  tr > th,
  td {
    font-size: 0.95rem;
    padding: 0 8px;
    &:last-child {
      text-align: right;
    }
  }
  tr > td {
    font-size: 0.95rem;
  }
}
.aprrove-task {
  background-color: rgb(255, 255, 255);
  td,
  th {
    &:first-child {
      position: sticky;
      left: 0;
      width: 50px;
      z-index: 1;
      background-color: rgb(255, 255, 255);
      border-right: 1px solid #ddd;
    }
  }
  td,
  th {
    &:nth-child(2) {
      position: sticky;
      left: 49px;
      width: 50px;
      z-index: 1;
      background-color: rgb(255, 255, 255);
      border-right: 1px solid #ddd;
      font-size: 00.95rem;
    }
  }
}

.supervisor-dashboard {
  .sizeSmall {
    padding: 0 6px;
    text-align: left;
  }
  tr {
    td {
      text-align: left;
      padding: 1px 4px;
    }
  }
  th {
    padding: 12px 6px;
  }
}
.future-dashboard {
  tr {
    th {
      padding: 16px;
      text-align: left;
    }
    td {
      text-align: left;
      padding: 8px 16px;
    }
  }
}

@media (max-width: 1200px) {
}
